<template>
  <v-dialog
    v-model="dialog"
  >
    <template #activator="{ on }">
      <v-list-item
        v-bind="on"
        @click.stop="dialog = true"
      >
        <v-list-item-icon class="mr-4">
          <v-icon small>
            fal fa-user-times
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content><v-list-item-title>This is not my patient</v-list-item-title></v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-text class="pt-2 pb-0">
        <v-radio-group v-model="reason">
          <span class="mb-2">{{ patient.first_name }} {{ patient.last_name }}</span>
          <v-radio
            value="is no longer"
            label="is no longer my patient"
          />
          <v-radio
            value="was never"
            label="was never my patient"
          />
        </v-radio-group>
        <v-textarea
          v-model="comments"
          outlined
          rows="3"
          label="Comments"
          class="mb-0 pb-0"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="default"
          width="50%"
          @click="dialog = false"
        >
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          width="50%"
          @click="notMyPatient"
        >
          {{ $t('Submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      hash: {
        type: String,
        default: null,
      },
      facility: {
        type: Object,
        default: () => ({}),
      },
      patient: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      dialog: false,
      reason: null,
      comments: null,
      loading: false,
    }),
    methods: {
      notMyPatient () {
        this.loading = true
        this.axios.post('asp/' + this.hash + '/patients/' + this.patient.uuid, {
          facility: this.facility.uuid,
          reason: this.reason,
          comments: this.comments,
        })
          .then(() => {
            this.dialog = false
            this.$toast.success("Thank you for letting us know. We'll get working on it right away.")
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading = false })
      },
    },
  }
</script>

<style>

</style>
